// assets/js/site-add.js

require('../../../Resources/OneBidSiteBundle/public/js/jquery.color-2.1.2.min.js');
//require('../../../Resources/OneBidSiteBundle/public/js/jquery.lazy.min.js');
require('../../../Resources/OneBidSiteBundle/public/js/jquery.flip.min.js');
require('../../../Resources/OneBidSiteBundle/public/js/hammer.min.js');
global.mdc = require('material-components-web');
global.MobileDetect = require('mobile-detect');
// global.intlTelInput = require('intl-tel-input');
//require('../../../web/static/intl-tel-input-utils.js');
require('../../../Resources/OneBidSiteBundle/public/js/jquery.fancybox-3.5.7.min.js');
require('../../../Resources/OneBidSiteBundle/public/js/print.min.js');
require('../../../Resources/OneBidSiteBundle/public/js/onebid-two-columns.js');
require('../../../Resources/OneBidSiteBundle/public/js/horizontal-scroll.js');
require('../../../Resources/OneBidSiteBundle/public/js/autocomplete-country.js');

global.socketio = require('../../../Resources/OneBidSiteBundle/public/js/socket.io-4.3.2.min.js');
