/**
 * OneBid Two column layouts
 */

$(document).ready(function() {

  // fix height of elements on two-columns view with big photo cover
  if ($('#container-fix-height').length) {
    var bh = $('body').height();
    var hh = $('header').height();
    bh = bh - hh;
    dh = $('#container-fix-height').height();
    if (dh < bh) {
      $('#container-fix-height').css('min-height', bh+'px');
    }
  }

});
