/**
 * Horizontal Scroll Boxes Controls for OneBid
 * @author Tomek Grzechowski <keo@becomewater.com>
 */

$(document).ready(function() {
	function sideScroll(id,direction,speed,distance,step){
		var element = document.getElementById(id);
		scrollAmount = 0;
		var slideTimer = setInterval(function(){
			if(direction == 'left'){
				element.scrollLeft -= step;
			} else {
				element.scrollLeft += step;
			}
			scrollAmount += step;
			if(scrollAmount >= distance){
				window.clearInterval(slideTimer);
			}
		}, speed);
	}

	$('.horizontal-scroll-btn-left').click(function(){
		var id = $(this).data('scroll');
		var distance = $('#'+id).find('.box-horizontal-scroll-box').first().width();
		sideScroll(id,'left',25,distance,10);
	});

	$('.horizontal-scroll-btn-right').click(function(){
		var id = $(this).data('scroll');
		var distance = $('#'+id).find('.box-horizontal-scroll-box').first().width();
		sideScroll(id,'right',25,distance,10);
	});

	// horizontal scroll menus
	// scroll active menu element into view
	if ($(".nav-scroll-to-view .active").length ) {
		$(".nav-scroll-to-view .active")[0].scrollIntoView(false,{inline:'end'});
	}
});
